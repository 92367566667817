@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url("./assets/font/Inter_18pt-Light.ttf") format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/Inter_18pt-Regular.ttf") format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url("./assets/font/Inter_18pt-Medium.ttf") format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url("./assets/font/Inter_18pt-SemiBold.ttf") format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url("./assets/font/Inter_18pt-Bold.ttf") format('ttf');
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  /*font-size: 16px;*/
  font-size: calc(100vw / 120);
  color: #fff;
  font-family: Inter, Arial, sans-serif;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #000 url("./assets/img/page-bg.png") 0 0 no-repeat;
  background-size: 100% 100%;
}

/*header start*/
header {
  padding: 0.625rem 3.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 18, 68, 0.4);
}

.date {
  display: flex;
  font-size: 1.125rem;

  &__date {
    font-weight: 600;
    letter-spacing: 0.03rem;
    opacity: 0.7;
  }

  &__separator {
    width: 0.0625rem;
    height: 1.3125rem;
    margin: 0 0.625rem;
    background-color: #fff;
    opacity: 0.3;
  }

  &__time {
    opacity: 0.7;
  }
}

.page-header {
  display: flex;
  align-items: center;

  &__title {
    padding-left: 1.6875rem;
    font-size: 1.75rem;
    background: transparent url("./assets/img/drop.svg") 0 center/1.17rem auto no-repeat;
    letter-spacing: 0.03rem;
  }

  &__separator {
    width: 0.125rem;
    height: 2.125rem;
    margin: 0 1.75rem;
    background-color: #fff;
  }

  &__subtitle {
    font-size: 1.25rem;
    letter-spacing: 0.04rem;
  }

  &__batery-indicator {
    font-size: 1.25rem;

    span {
      color: #00ca33;
      //color: #00C08B;
    }
  }
}

.pseudo-account {
  width: 13.375rem;
  height: 3.5625rem;
  background: transparent url("./assets/img/pseudo-account.png") 0 0/100% 100% no-repeat;
}


@media screen and (max-width: 768px) {
  html {
    font-size: 16px;
  }

  header {
    padding: 1rem 0;
    flex-wrap: wrap;

    &-col {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }

  .date__separator {
    display: none;
  }

  .page-header {
    flex-wrap: wrap;
    justify-content: center;

    &__separator {
      display: none;
    }
  }
}

/*header end*/

/*main start*/
.main-wrapper {
  flex: 1 1 auto;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2.375rem 5.5rem 0;
  position: relative;

  &__item {
    position: relative;
    width: 48.8%;
    padding-bottom: 22.7%;
    margin-bottom: 2.375rem;
    background-color: #fff;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    //overflow: hidden;

    &_inner {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__modal-container {
    position: absolute;
    right: 5.5rem;
    top: 2.375rem;
    width: calc((100% - 11rem)*0.488);
    padding-bottom: 0;
    overflow: hidden;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    opacity: 0.1;
    transition: opacity .2s, padding .2s;
    z-index: 10;

    &.show {
      opacity: 1;
      padding-bottom: calc(41.237% + 2.375rem);
    }
  }

  &__modal-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255);
  }

  &__scenario-buttons {
    position: fixed;
    left: 0.625rem;
    bottom: 8.625rem;
    display: flex;
    flex-direction: column;
  }

  &__scenario-button {
    position: relative;
    width: 3.25rem;
    height: 3.25rem;
    background-color: rgba(0, 0, 0, 0.32);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 0.125rem solid rgba(255, 255, 255, 0.4);
    box-shadow: 0 0.5rem 1.25rem 0 rgba(9, 26, 72, 0.06);
    border-radius: 5rem;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.8);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    &+& {
      margin-top: 1.5rem;
    }

    &_critical {
      background-image: url("./assets/img/critical.svg");
      background-size: 1.667rem auto;
    }

    &_warn {
      background-image: url("./assets/img/warning.svg");
      background-size: 1.833rem auto;
    }

    &_info {
      background-image: url("./assets/img/sleep.svg");
      background-size: 1.5rem auto;
    }
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.75rem 2rem 1rem 2.5rem;

    /* To hide chart top-border */
    /*
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
    background-color: #FFFFFF;
    */
  }

  &__title {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    color: #2D3E55;

    &_note {
      font-weight: 300;
    }
  }

  &__content {
    flex-grow: 1;
  }
}

.iframe-wrapper {
  position: relative;

  &__inner {
    position: absolute;
    left: 1.25rem;
    /* 1.75rem - 0.5rem , where 0.5rem is to compensate padding from grafana */
    top: 0;
    width: calc(100% - 2.5rem);
    height: calc(100% - 1.25rem);
    /*width: calc(100% - 3.5rem);*/
    /*height: calc(100% - 1.75rem);*/
    overflow: hidden;
  }

  & iframe {
    width: 100%;
    height: 100%;
  }
}

.edge-cut {
  /* used to cut chart border */
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  margin: -1.5px;
}

.chart-date {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.5rem 2.875rem 0.5rem 0.75rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  background: #EEF4FC url("./assets/img/calendar.svg") calc(100% - 0.5rem) 50%/1.375rem auto no-repeat;
  color: #6E7E9C;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  border: none;
}


.modal {
  &-header {
    display: flex;
    align-items: center;
    padding: 1.4375rem 2.0625rem 1.4375rem 3.375rem;
    color: #000;
    border-bottom: 2px solid rgba(100, 100, 100, 0.4);

    &_type-critical {
      color: rgba(237, 98, 79, 1);
      background: linear-gradient(90deg, rgba(237, 98, 79, 0.24) 0%, rgba(47, 98, 79, 0.0672) 100%);
      border-color: rgba(237, 98, 79, 0.4);
    }

    &_type-warn {
      color: rgba(255, 193, 7);
      background: linear-gradient(90deg, rgba(255, 203, 7, 0.24) 0%, rgba(87, 98, 79, 0.0672) 100%);
      border-color: rgba(255, 193, 7, 0.4);
    }

    &_type-info {
      color: rgba(23, 162, 184);
      background: linear-gradient(90deg, rgba(23, 162, 184, 0.24) 0%, rgba(47, 98, 109, 0.0672) 100%);
      border-color: rgba(23, 162, 184, 0.4);
    }
  }

  &-icon {
    width: 2.708rem;
    height: 2.708rem;
  }

  &-title {
    font-size: 2.5rem;
    line-height: 4rem;
    font-weight: 700;
  }

  &-icon+&-title {
    margin-left: 1.5rem;
  }

  &-close {
    width: 2.75rem;
    height: 2.75rem;
    position: relative;
    margin-left: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 1.8125rem;
      height: 0.2375rem;
      background: rgba(45, 62, 85, 1);
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      border-radius: 2rem;
    }

    &:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  // modal-date
  &-date {
    display: inline-block;
    white-space: nowrap;
    padding: 0.375rem 2.875rem 0.375rem 0.75rem;
    margin-left: auto;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;
    color: #6E7E9C;
    background: #EEF4FC url("./assets/img/calendar.svg") calc(100% - 0.5rem) 50%/1.375rem auto no-repeat;
    font-size: 1.125rem;
    line-height: 1.625rem;
    font-weight: 600;
    border: 1px solid #D6DDE8;
  }

  &-body {
    padding: 3.125rem;
    color: #6E7E9C;
  }

  &-notification {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.8rem;

    &__sign-icon {
      display: inline-block;
      width: 1.604375rem;
      height: 1.4325rem;
      margin-right: 1.0625rem;
    }

    &__sign {
      display: inline-block;
      line-height: 1.75rem;
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem 0.625rem 0.698rem;
      margin: 0 0.5rem;

      &_critical {
        background-color: rgba(237, 98, 79, 0.12);
        color: rgba(237, 98, 79, 1);
        //background: rgba(237, 98, 79, 0.12) url("./assets/img/flood-house.svg") 1rem 50%/1.604375rem auto no-repeat;
      }

      &_warn {
        background-color: rgba(255, 193, 7, 0.12);
        color: rgba(255, 193, 7);
      }

      &_info {
        background-color: rgba(23, 162, 184, 0.12);
        color: rgba(23, 162, 184);
      }
    }

    b {
      color: #2D3E55;
    }
  }

  &-separator {
    width: 100%;
    border-bottom: 0.125rem solid #D6DDE8;
    margin: 3.125rem 0;
  }

  &-recommendations {
    padding: 1rem 1.25rem;
    border: 0.125rem solid #D6DDE8;
    color: #2D3E55;
    background: #EEF4FC;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;

    &__inner {
      padding-right: 1.375rem;
      max-height: 17rem;
      overflow: auto;
    }

    &__title {
      color: #6E7E9C;
      text-transform: uppercase;
    }

    &__content {
      position: relative;
      margin-top: 1.25rem;
      min-height: 4rem;

      p,
      div {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5;
        color: #2D3E55;
      }
    }
  }

  &-date+&-notification {
    margin-top: 2rem;
  }

  &-notification+&-separator {
    margin-top: 3.125rem;
  }

  &-notification+&-recommendations {
    margin-top: 1.75rem;
  }
}

.reset-button-container {
  // TODO Just for demo
  position: fixed;
  right: 3.4rem;
  top: 0.65rem;
  width: 13.375rem;
  height: 3.5625rem;
  background-color: #fff;
  opacity: 0;
}

.reset-button {
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  main {
    padding: 2rem 2rem 0;
  }

  .main__item {
    width: 100%;
    /*padding-bottom: 46.48%;*/
    padding-bottom: 65%;
  }

  .iframe-container {
    iframe {
      padding: 0;
    }

    &__header {
      padding: 0.5rem;
    }

    &__title {
      font-size: 0.75rem;
    }
  }
}

/*main end*/

/*footer start*/
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 1rem 2rem;

  &-col {
    max-width: 100%;
    padding: 0 1.75rem;
  }
}

.logo-footer {
  display: block;

  img {
    max-width: 100%;
  }
}

.spinner-wrapper {
  position: absolute;
  width: 2.25rem;
  height: 2.25rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  display: block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  border-left-color: #54B8F7;

  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  .footer {
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000);

    &-col {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }

  .logo-footer {
    max-width: 50%;
  }
}

/*footer end*/

/* Components start*/
/*Valve indicator*/

.valve-indicator {
  position: absolute;
  width: 92.02%;
  padding-bottom: 17.14%;
  top: 24.24%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;
  transition: opacity .2s;

  &-container {
    position: relative;
  }

  &__hidden {
    opacity: 0;
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.pipe,
.valve {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all .2s;
}

.valve {
  transform: rotate(-180deg);
}

.pipe {
  &__open {
    background: transparent url("./assets/img/pipe-open_2x.png") 0 0/contain no-repeat;
    opacity: 0;
  }

  &__close {
    background: transparent url("./assets/img/pipe-close_2x.png") 0 0/contain no-repeat;
  }
}

.valve {
  &__open {
    background: transparent url("./assets/img/valve-open_2x.png") 50% 50%/25.89% auto no-repeat;
    opacity: 0;
  }

  &__close {
    background: transparent url("./assets/img/valve-close_2x.png") 50% 50%/25.89% auto no-repeat;
  }
}

.valve-indicator__open .valve {
  transform: rotate(0);
}

.valve-indicator__open .pipe__open {
  opacity: 1;
}

.valve-indicator__open .valve__open {
  opacity: 1;
}

/* Notification */
.notification {
  position: fixed;
  left: 1rem;
  top: 1rem;
  background-color: #DC3545;
  color: #ffffff;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  padding: 1rem;
  opacity: 1;
  transition: opacity .2s;

  &__hidden {
    opacity: 0;
  }
}


.chart-container {
  width: 100%;
}

.chart-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.chart-container__title {
  margin: 0;
}

.chart-container__content {
  width: 100%;
}

.chart-wrapper {
  width: 100%;
}

.chart-wrapper__inner {
  position: relative;
}

.chart-frames {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.chart-frame iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.chart-addon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.chart-addon__content {
  pointer-events: auto;
}